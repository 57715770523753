<template>
  <div>
    <div class="loginBg"></div>
    <div class="classLoginBox">
      <h2>远程开关机</h2>
      <van-form @submit="onSubmit">
        <van-cell-group inset>
          <van-field
            v-model="userData.user"
            label="账号"
            placeholder="账号"
            :rules="[{ required: true, message: '请填写账号' }]"
          />
          <van-field
            v-model="userData.password"
            type="password"
            name="密码"
            label="密码"
            placeholder="密码"
            :rules="[{ required: true, message: '请填写密码' }]"
          />
          <van-field
            v-model="userData.device_name"
            label="设备名称"
            placeholder="如不填默认家"
          />
        </van-cell-group>
        <div style="margin: 16px">
          <van-button block type="primary" native-type="submit">
            登录
          </van-button>
        </div>
      </van-form>
      <div style="margin: 16px">
        <van-button type="success" block @click="borrow">弹充电宝</van-button>
      </div>
    </div>
    <!-- end -->

    <van-dialog
      v-model:show="isShow"
      show-cancel-button
      :before-close="beforeClose"
    >
      <van-cell-group>
        <van-field
          class="padety"
          v-model="value"
          label="请输入密码"
          type="password"
          placeholder="请输入密码"
        />
      </van-cell-group>
    </van-dialog>
  </div>
</template>

<script>
import { httpPOst, portable } from "@/request/api";
import { onBeforeMount, reactive, ref, toRefs } from "vue";
import { useRouter } from "vue-router";
import { useStore } from 'vuex';
import { Toast } from "vant";
import { Dialog } from "vant";
export default {
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },
  setup() {
    const isShow = ref(false);
    const value = ref("");
    const router = useRouter(); //路由
    const store = useStore();
    const userData = reactive({
      //请求需要的参数
      user: "",
      password: "",
      device_name: "家",
    });
    
    onBeforeMount(() => {
      // inst();
    });

    const borrow = () => {
      isShow.value = true;
      // reactive赋值必须这样写
      // let userData1 = {user:'1',password:'1',device_name:'1'}
      // let userData = reactive({
      //   data:userData1
      // })
      // console.log(userData.data)
    };
    const beforeClose = (action) =>
      new Promise((resolve) => {
          if (action === "confirm") {
            if (value.value == "123456") {
              isShow.value = false;
              value.value = "";
              cdb();
              resolve(); // 关闭
            } else {
              Toast("密码错误");
              value.value = "";
              resolve(false);
              return;
            }
          } else if (action === "cancel") {
            value.value = "";
            resolve(true); // 关闭
          }
      });
    const cdb = () => {
      let data = {
        ieim: "VTSC8532106000001",
        ca_state: "2_2_2_2_2_2",
        type: "16",
      };
      portable(data).then((res) => {
        if(res.data.code != 1000){
            Toast.fail(res.data.message);
            return
          }
          Toast.success(res.data.message);
      });
    };
    const onSubmit = (values) => {
      //登录
      let data = {
        sgdz_account: userData.user,
        sgdz_password: userData.password,
        device_name: "家",
        value: "11",
      };
      httpPOst(data).then((res) => {
        if (res.data.status == "-2") {
          Toast.fail("密码错误");
          return;
        }
        store.commit("__login",userData);
        router.push("/"); //跳转
      });
    };
    return {
      userData,
      isShow,
      value,
      onSubmit,
      borrow,
      beforeClose
    };
  },
};
</script>
<style scoped>
.loginBg {
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  bottom: 0;
  background: url("../assets/login.jpg") no-repeat;
  background-size: 100% 100%;
  z-index: -1;
}
.classLoginBox {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
}
.mariht {
  margin-top: 10px;
}
.padety {
  padding: 15px;
}
</style>
